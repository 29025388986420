import $ from 'jquery';
import Fingerprint2 from 'fingerprintjs2';

class PlayHubAPI {

    constructor() {
        this.api_base_url = "https://dev.digitalizehub.com/api/";
        this.url = "localhost";
        this.deviceID = null;
        this.setBrowserFingerprint();
        this.authToken = this.getAuthToken();
        this.clientToken = null;
        this.headers = {}
        this.deviceIDGenerated = false;

    }

    getHeaders() {
        return {
            'AUTH-TOKEN': this.authToken,
            'DEVICE-ID': this.deviceID,
            'CLIENT-TOKEN': this.getClientToken(),
            'REFERRER-LINK': this.url
        }
    }

    getClientToken(){
        return this.clientToken ? this.clientToken : localStorage.getItem('client-token');
    }

    setAuthToken(auth_token){
        localStorage.setItem('auth-token', auth_token)

        this.auth_token = auth_token;
    }

    resetAuthToken(){
        localStorage.removeItem('auth-token')
        this.authToken = this.getAuthToken();
    }

    setBrowserFingerprint() {
        var that = this;
        var deviceID = localStorage.getItem('device-id');
        
        if (!deviceID && !this.deviceIDGenerated){
            Fingerprint2.get({excludes: {webgl: true, audio: true, fonts: true, fontsFlash: true, canvas: true}}, function (components) {

                var values = components.map(function (component) { 
                    return component.value 
                });

                var murmur = Fingerprint2.x64hash128(values.join(''), 31)
                
                that.deviceID = murmur;
                localStorage.setItem("device-id", murmur);
            });
        }else{
            this.deviceID = deviceID
        }
    }

    getAuthToken() {

        var authToken = localStorage.getItem('auth-token')

        return authToken ?  authToken : null;
    }

    post(path, params, successFn, errorFn) {

        this.call(path, 'POST', params, successFn, errorFn)

    }

    call(path, type, params, successFn, errorFn) {

        var that = this;

        if(!this.deviceID)
            setTimeout(function (){
                that.call(path, type, params, successFn, errorFn)
            }, 500);
        else{
            $.ajax({
                url: that.api_base_url + path,
                type: type,
                dataType: 'json',
                headers: that.getHeaders(),
                data: params,
                success: function (response) {
                    if (response.status == 'FAIL' && response.message == 'INVALID_AUTH_TOKEN') {
                        localStorage.removeItem('auth-token')
                        document.location.href = '/signin';
                    } else {
                        if(response.payload && response.payload.providerop){
                            localStorage.setItem('client-token', response.payload.providerop.client_token)
                            that.clientToken = response.payload.providerop.client_token;
                        }

                        successFn(response)
                    }
                },
                error: function (){
                    if(errorFn){
                        errorFn();
                    }
                }
            });
        }
    }
}

export default PlayHubAPI;
